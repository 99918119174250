import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes,Route} from "react-router-dom";
import MasterHome from './master/MasterHome';
import Contact from './Contact';
import  AddFormClient from "./master/AddClientForm";
import ListUser from './master/ListUser';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <BrowserRouter>
    <Routes>
      <Route path="/"  element={<App />}/>
      <Route path="masterhome" element={<MasterHome/>}/>
      <Route path="contact" element={<Contact/>}/>
      <Route path="addclientform" element={<AddFormClient/>}/>
      <Route path="listuser" element={<ListUser/>}/>


    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
