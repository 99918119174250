import Header from '../Header';
import Menu from '../Menu';
import Dashboard from '../Dashboard';
import Footer from '../Footer';

function MasterHome() {
  return (
    <div class="wrapper">
    <Header/> 
    <Menu/> 
    <Dashboard/>
    <Footer/>  </div>
  )
}

export default MasterHome;
